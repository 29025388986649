<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="权限名称" :required="true">
            <el-input v-model="form.permissionsName" placeholder="输入权限名称" class="formInput" max-length="20" />
          </el-form-item>
          <el-form-item label="权限编码" :required="true">
            <el-input v-model="form.permissionsCode" placeholder="输入权限编码" class="formInput" :disabled="info.addOr==='upd'" />
          </el-form-item>
          <el-form-item label="路由地址" :required="true">
            <el-input v-model="form.route" class="formInput" placeholder="输入路由地址" :disabled="info.addOr==='upd'" />
          </el-form-item>
          <el-form-item label="图标">
            <el-input v-model="form.icon" class="formInput" placeholder="输入图标"/>
          </el-form-item>
          <el-form-item label="类型" :required="true">
            <el-select v-model="form.type" class="formInput w-100" placeholder="输入类型">
              <el-option v-for="(v,i) in menuLevel" :key="i" :value="v.dictCode" :label="v.dictName" />
            </el-select>
          </el-form-item>
          <el-form-item v-show="form.type!=0" label="父菜单" :required="true">
            <el-cascader placeholder="选择父菜单"
              v-model="form.parentId"
              :options="permission"
              filterable
              clearable
              class="formInput w-100"
              :props="{lazy:false,value:'id',children:'sons',label:'permissionsName',checkStrictly:true,emitPath:false}"
            />
          </el-form-item>
          <el-form-item label="排序" :required="true">
            <el-input v-model="form.sort" placeholder="输入排序" class="formInput" max-length="20" />
          </el-form-item>
          <el-form-item label="是否展示" :required="true">
            <el-select v-model="form.isShow" class="formInput w-100" placeholder="输入排序">
              <el-option :value="0" label="否" />
              <el-option :value="1" label="是" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getDictParentCode, permissions } from '@/api'
export default {
  name: 'Operate',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    },
    company: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      form: {
        permissionsName: '',
        permissionsCode: '',
        route: '',
        icon: '',
        type: '',
        parentId: null,
        sort: '',
        isShow: 1
      },
      menuLevel: [],
      permission: []
    }
  },
  created() {
    this.getDictParentCode()
    this.getPermission()
  },
  mounted() {
    this.upd()
  },
  methods: {
    upd() {
      if (this.info.addOr === 'upd') {
        this.$axiosReq(permissions + this.row.id, null, null, 'get').then(res => {
          this.form = res.data
          this.form.type = res.data.type + ''
          this.form.companyNames = null
          this.form.roleNames = null
          this.form.parentId = res.data.parentId
        })
      }
    },
    getDictParentCode() {
      this.$axiosReq(getDictParentCode + 'MENU_LEVEL', null, null, 'get').then(res => {
        this.menuLevel = res.data
      })
    },
    getPermission() {
      this.$axiosReq(permissions, null, {
        search: '',
        statu: 0,
        type: 1,
        companyId: this.$admin ? null : this.$companyId
      }, 'get').then(res => {
        this.permission = res.data
      })
    },
    // 提交添加
    commit() {
      if (this.$textNull(this.form.permissionsName)) {
        this.$message.error('请填写权限名称！')
        return
      } else if (this.$textNull(this.form.permissionsCode)) {
        this.$message.error('请填写权限编码！')
        return
      }
      if (this.form.type != 2) {
        if (this.$textNull(this.form.route)) {
          this.$message.error('请填写路由地址！')
          return
        }
      }
      if (this.form.type != 0) {
        if (this.$textNull(this.form.parentId) || this.form.parentId == 0) {
          this.$message.error('请选择父菜单！')
          return
        }
      }
      if (this.info.addOr === 'add') {
        this.$axiosReq(permissions, null, this.form, 'post').then(res => {
          this.$emit('get-list')
          this.$message.success(res.msg)
          this.close()
        })
      }
      // else if (this.info.addOr === 'addSon') {
      //   this.$axiosReq(permissions, null, this.form, 'post').then(res => {
      //     this.$emit('get-list')
      //     this.$message.success(res.msg)
      //     this.close()
      //   })
      // }
      else {
        this.$axiosReq(permissions + this.row.id, null, this.form, 'put').then(res => {
          this.$emit('get-list')
          this.$message.success(res.msg)
          this.close()
        })
      }
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.el-form{
    padding-right: 25px;
}
</style>
